
import 'ant-design-vue/dist/antd.css';

import { defineComponent } from 'vue';

import koKR from 'ant-design-vue/es/locale/ko_KR';

export default defineComponent({
  name: 'App',
  setup() {
    return {
      koKR,
    };
  },
});
