import { EditOutlined } from '@ant-design/icons-vue';
import UserOutlined, {
  UserOutlinedIconType,
} from '@ant-design/icons-vue/lib/icons/UserOutlined';
import { createRouter, createWebHistory } from 'vue-router';
import type { RouteRecordRaw } from 'vue-router';
import { useCookies } from 'vue3-cookies';

type IRouteRecordSingleView = RouteRecordRaw & {
  label?: string;
  icons?: UserOutlinedIconType[] | UserOutlinedIconType;
  children?: Array<IRouteRecordSingleView>;
};

export const routes: Array<IRouteRecordSingleView> = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    redirect: '/change',
    children: [
      {
        path: '/change',
        name: 'ChangeProcess',
        component: () => import('pages/results/Index.vue'),
        props: { apiUrl: 'jobs/' },
        label: 'virtual-face-text',
        icons: [UserOutlined, EditOutlined],
      },
    ],
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: () => import('pages/signIn/Index.vue'),
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(function (to, from, next) {
  const { cookies } = useCookies();
  const accessToken = cookies.get('accessToken');

  if (to.name !== 'SignIn' && !accessToken) {
    next('/sign-in');
  } else if (to.name === 'SignIn' && accessToken) {
    next('/');
  } else {
    next();
  }
  // to : 이동할 url
  // from : 현재 url
  // next : to에서 지정한 url로 이동하기 위해 꼭 호출해야 하는 함수
});

export default router;
